<template>
  <table-view
    :filterable=false
    :filter-form="dataForm"
    ref="tableView"
    url="/user/role/listForPage"
    delete-url="/user/role/deleteById"
    keyword-prop="roleName"
    :batch-removable="isAuthed('user_role_delete')"
    :addable="isAuthed('user_role_save')"
    :editable="isAuthed('user_role_update')"
    :headers="headers"
    show-index
    selectable
    single
  >
    <el-form slot="search" @keyup.enter.native="query">
      <el-input v-model="dataForm.roleName" clearable placeholder="角色名称"></el-input>
      <el-button size="mini" @click="query" type="primary">查询</el-button>
    </el-form></table-view>
</template>

<script>
import TableView from '@/components/templates/table-view'

export default {
  name: 'person-role',

  components: { TableView },

  data () {
    return {
      headers: [
        { id: 1, prop: 'roleName', label: '角色', width: 200 },
        { id: 2, prop: 'userNumber', label: '用户数量', width: 120 },
        { id: 3, prop: 'remarks', label: '备注', minwidth: 120 },
        { id: 4, prop: 'createTime', label: '创建时间', width: 100 }
      ],
      dataForm: {
        roleName: ''
      }
    }
  },

  methods: {
    query () {
      this.$refs.tableView.queryData()
    }
  }
}
</script>
